import { type AppProps } from 'next/app'
import React, { useEffect, type ReactElement } from 'react'
import { appWithTranslation } from 'next-i18next'
import { NextPage } from 'next'
import Head from 'next/head'
import { SessionProvider, signIn, useSession } from 'next-auth/react'
import { ThemeProvider } from '@uniqs-labs/ui-react-lib'
import { TeamProvider } from '../hooks/useMyTeam'
import { TemplateProvider } from '../hooks/useMyTemplate'
import AppLayout from '../components/AppLayout'
import PulsingLogo from '../components/PulsingLogo'

// global styles
import '@uniqs-labs/ui-react-lib/styles'
import '../styles/global-override.css'
import Analytics from '../components/Analytics'

export type NextPageWithAuth<P = {}, IP = P> = NextPage<P, IP> & {
  auth?: {
    sessionRequired?: boolean
    authRequired: boolean
    teamRequired?: boolean
    adminRequired?: boolean
  }
  layout?: {
    navbarVisible?: boolean
  }
}

type AppPropsWithAuth = AppProps & {
  Component: NextPageWithAuth
}

interface IAuthP {
  children: ReactElement
  teamRequired: boolean | undefined
  navbarVisible: boolean | undefined
  adminRequired: boolean | undefined
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Auth({
  children,
  teamRequired = false,
  navbarVisible = true,
  adminRequired = false,
}: IAuthP) {
  const { data: session, status } = useSession()
  const isUser = !!session?.user
  const isAdmin = session?.user?.admin

  useEffect(() => {
    if (status === 'loading') return // Do nothing while loading
    if (!isUser) signIn() // If not authenticated, force log in
  }, [status, isUser])

  if (!isUser || (adminRequired && !isAdmin)) return <PulsingLogo />

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
      </Head>
      <TeamProvider>
        <TemplateProvider>
          <AppLayout navbarVisible={navbarVisible} teamRequired={teamRequired}>
            {children}
          </AppLayout>
        </TemplateProvider>
      </TeamProvider>
    </>
  )
}

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppPropsWithAuth) {
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        {process.env.NODE_ENV !== 'production' && (
          <meta
            name="google-site-verification"
            content="r8_9CePLan5rdhdu6Ll-g_NQ3lCv9-uIx6tltO_1wvk"
          />
        )}
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
        />
        <meta name="theme-color" content="#23c4b6" />
        <meta name="description" content="Simplify how you build amazing app." />
        <meta name="keywords" content="mail, email, builder, mail marketing, automation" />
        <link rel="icon" href="/favicon.ico" />
        <link href="/favicon.ico" rel="icon" type="image/ico" sizes="16x16" />
        <link href="/favicon.ico" rel="icon" type="image/ico" sizes="32x32" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <title>Mail Builder | Create amazing email templates</title>
        {/* Normal PWA stuff */}
        <meta name="msapplication-TileColor" content="#212529" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="format-detection" content="telephone=no" />
        <link rel="manifest" href="/manifest.json" />
        {/* Apple PWA stuff */}
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-title" content="Mail Builder" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <link rel="apple-touch-icon" href="/icons/icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/icons/touch-icon-ipad.png" />
        <link rel="apple-touch-icon" sizes="167x167" href="/icons/touch-icon-ipad-retina.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/icons/touch-icon-iphone-retina.png" />
      </Head>
      <Analytics />

      <ThemeProvider>
        {Component?.auth?.sessionRequired || Component.auth?.authRequired ? (
          <SessionProvider session={session}>
            {Component.auth?.authRequired ? (
              <Auth
                navbarVisible={Component.layout?.navbarVisible}
                teamRequired={Component.auth?.teamRequired}
                adminRequired={Component.auth?.adminRequired}
              >
                <Component {...pageProps} />
              </Auth>
            ) : (
              <Component {...pageProps} />
            )}
          </SessionProvider>
        ) : (
          <Component {...pageProps} />
        )}
      </ThemeProvider>
    </>
  )
}

export default appWithTranslation(MyApp)

/* eslint-disable no-console */
export const fetchGetJSON = (url: string) =>
  fetch(url)
    .then((apiResponse) => {
      if (apiResponse.status === 403 || apiResponse.status === 404 || apiResponse.status === 500) {
        throw new Error(`There was an ${apiResponse.status} error`)
      }
      return apiResponse
    })
    .then((apiResponse) => apiResponse.json())
    .catch((e) => console.log(e))

export const fetchPostJSON = (url: string, data?: {}) =>
  // Default options are marked with *
  fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(data || {}), // body data type must match "Content-Type" header
  })
    .then((apiResponse) => {
      if (apiResponse.status === 403 || apiResponse.status === 404 || apiResponse.status === 500) {
        throw new Error(`There was an ${apiResponse.status} error`)
      }
      return apiResponse
    })
    .then((apiResponse) => apiResponse.json())
    .catch((e) => console.log(e))

export const fetchDeleteJSON = (url: string, data?: {}) =>
  // Default options are marked with *
  fetch(url, {
    method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(data || {}), // body data type must match "Content-Type" header
  })
    .then((apiResponse) => {
      if (apiResponse.status === 403 || apiResponse.status === 404 || apiResponse.status === 500) {
        throw new Error(`There was an ${apiResponse.status} error`)
      }
      return apiResponse
    })
    .then((apiResponse) => apiResponse.json())
    .catch((e) => console.log(e))

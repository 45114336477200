/* eslint-disable default-param-last */
/* eslint-disable @typescript-eslint/default-param-last */
import { useLocalStorage, useMountedRef, Notification } from '@uniqs-labs/ui-react-lib'
import React, {
  type Context,
  createContext,
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useState,
  useReducer,
} from 'react'
import { useRouter } from 'next/router'
import { type FullTeam } from '../adapters/teamManagement'
import { type ITeamManagementAPI } from '../pages/api/team'

const uri = '/api/team'
type ContextTypes = {
  data: { selected: FullTeam | null | undefined; teams: [] | (FullTeam | null)[] }
  error: any
  loading: boolean
  isAllowed: boolean
  isReset: boolean
  setTeam: ({ id }: { id: string }) => void
  resetTeams: ({ callback }: { callback?: (apiResponse: any) => void }) => void
  getUniqueTeam: ({
    id,
    callback,
    failback,
  }: {
    id?: string
    callback?: (apiResponse: any) => void
    failback?: (apiResponse: any) => void
  }) => void
  createTeam: ({
    teamName,
    teamImage,
    callback,
    infoMessage,
    errorMessage,
  }: {
    teamName: string
    teamImage: string
    callback?: (apiResponse: any) => void
    infoMessage?: string
    errorMessage?: string
  }) => any
  joinTeamOrUpdateMembership: ({
    nickname,
    image,
    id,
    callback,
    infoMessage,
    errorMessage,
  }: {
    nickname: string
    image?: string
    id?: string
    callback?: (apiResponse: any) => void
    infoMessage?: string
    errorMessage?: string
  }) => any
  deleteTeam: ({
    callback,
    failback,
    errorMessage,
    infoMessage,
  }: {
    callback?: (apiResponse: any) => void
    failback?: (apiResponse: any) => void
    infoMessage?: string
    errorMessage?: string
  }) => any
  toggleAdmin: ({
    errorMessage,
    infoMessage,
    emailFromMembershipToToggleAdmin,
  }: {
    infoMessage?: string
    errorMessage?: string
    emailFromMembershipToToggleAdmin: string
  }) => any

  disconnectMembership: ({
    emailFromMembershipToDisconnect,
    callback,
    failback,
    errorMessage,
    infoMessage,
  }: {
    emailFromMembershipToDisconnect: string
    callback?: (apiResponse: any) => void
    failback?: (apiResponse: any) => void
    infoMessage?: string
    errorMessage?: string
  }) => any
  connectOrCreateMembership: ({
    emailFromMembershipToAdd,
    admin,
    teamName,
    errorMessage,
    infoMessage,
  }: {
    emailFromMembershipToAdd: string
    admin?: boolean | undefined
    teamName: string
    infoMessage?: string
    errorMessage?: string
  }) => any
  updateTeamName: ({
    name,
    errorMessage,
    infoMessage,
  }: {
    name: string
    infoMessage?: string
    errorMessage?: string
  }) => any
}

const TeamContext = createContext({}) as unknown as Context<ContextTypes>

const useMyTeam = () => useContext(TeamContext)

type ReducerType = (
  state: { selected: FullTeam | undefined | null; teams: (FullTeam | null)[] | [] },
  {
    apiResponse,
    action,
  }: {
    apiResponse: FullTeam & FullTeam[]
    action: { type: string; callback: (response: any) => void; failback: () => void; payload: any }
  },
) => { selected: FullTeam | undefined | null; teams: (FullTeam | null)[] | [] }

const reducer: ReducerType = (
  state = { selected: undefined, teams: [] },
  { apiResponse, action },
) => {
  switch (action.type) {
    case 'SET_TEAM':
      return {
        ...state,
        selected: state.teams.find((team) => team?.id === action.payload.id) || state.selected,
      }
    case 'ADD_TEAM':
      return { selected: apiResponse, teams: [...state.teams, { ...apiResponse }] }
    case 'UPDATE_TEAM':
      return {
        selected: apiResponse,
        teams: [...state.teams.filter((team) => team?.id !== apiResponse.id), apiResponse],
      }
    case 'DELETE_TEAM':
      return {
        selected: state.teams.find((team) => team?.id !== apiResponse?.id) || undefined,
        teams: state.teams.filter((team) => team?.id !== apiResponse?.id),
      }
    case 'RESET_TEAMS':
      if (!apiResponse) return { selected: undefined, teams: [] }
      return {
        selected:
          apiResponse.find((team: FullTeam) => team?.id === action.payload.localStorageTeamId) ||
          apiResponse.slice(-1).pop(),
        teams: apiResponse.map((membership) => membership),
      }
    default:
      return state
  }
}

export function TeamProvider({ children }: { children: JSX.Element }) {
  const [data, reduceData]: [
    { selected: FullTeam | undefined | null; teams: (FullTeam | null)[] | [] },
    any,
  ] = useReducer(reducer, { selected: undefined, teams: [] })
  const [error, setError] = useState() as any
  const [info, setInfo] = useState() as any
  const [loading, setLoading] = useState(false)
  const [isAllowed, setIsAllowed] = useState(true)
  const [isReset, setReset] = useState(false)
  const mounted = useMountedRef()
  const [localStorageTeamId, setLocalStorageTeamId] = useLocalStorage(
    'selectedTeamId',
    data.selected?.id,
  )
  const router = useRouter()

  const teamId = data?.selected?.id

  const dispatch = useCallback(
    (action: {
      type: string
      callback?: ((requestedData: any) => void) | undefined
      failback?: ((apiResponse: any) => void) | undefined
      payload?: {
        id?: string
        method?: string
        body?: any
      }
      errorMessage?: string
      infoMessage?: string
    }) => {
      if (!uri) return
      if (!mounted.current) return
      if (!action) return
      setLoading(true)
      if (action.type === 'SET_TEAM') {
        reduceData({ action })
        setLoading(false)
      } else {
        fetch(uri, {
          method: action.payload?.method,
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(action.payload?.body),
        })
          .then((apiResponse) => {
            if (!mounted.current) throw new Error('component is not mounted')
            if (apiResponse.status === 403 || apiResponse.status === 404) {
              throw new Error(action.errorMessage || `Something went wrong.`)
            }
            return apiResponse
          })
          .then((apiResponse) => apiResponse.json())
          .then((apiResponse) => {
            reduceData({ apiResponse, action })
            return apiResponse
          })
          .then((apiResponse) => {
            if (action.infoMessage) {
              setInfo(action.infoMessage)
            }
            if (action.callback) {
              action.callback(apiResponse)
            }
          })
          .then(() => {
            setLoading(false)
            if (action.type === 'RESET_TEAMS') {
              setReset(true)
            }
          })
          .catch((returnedError) => {
            if (!mounted.current) return
            if (action.failback) {
              action.failback(returnedError)
            }
            setError(returnedError)
            setLoading(false)
          })
      }
    },
    [],
  )

  const getUniqueTeam = useCallback(
    ({ id, callback, failback }: Parameters<ContextTypes['getUniqueTeam']>[0]) => {
      if (!id && !teamId) return
      dispatch({
        type: 'UPDATE_TEAM',
        callback,
        failback,
        payload: {
          method: 'POST',
          body: { execute: 'getUniqueTeam', teamId: id || teamId },
        } as Parameters<ITeamManagementAPI['fetchGetUniqueTeam']>[0],
      })
    },
    [teamId],
  )

  const createTeam = useCallback(
    ({
      teamName,
      teamImage,
      callback,
      errorMessage = 'You can not be part of more than 2 free teams.',
      infoMessage,
    }: Parameters<ContextTypes['createTeam']>[0]) => {
      dispatch({
        type: 'ADD_TEAM',
        errorMessage,
        infoMessage,
        callback,
        payload: {
          method: 'POST',
          body: { execute: 'createTeam', teamName, teamImage },
        } as Parameters<ITeamManagementAPI['fetchCreateTeam']>[0],
      })
    },
    [],
  )

  const joinTeamOrUpdateMembership = useCallback(
    ({
      nickname,
      image,
      id,
      callback,
      errorMessage,
      infoMessage,
    }: Parameters<ContextTypes['joinTeamOrUpdateMembership']>[0]) => {
      dispatch({
        type: 'UPDATE_TEAM',
        errorMessage,
        infoMessage,
        callback,
        payload: {
          method: 'POST',
          body: { execute: 'joinTeamOrUpdateMembership', nickname, image, teamId: id || teamId },
        } as Parameters<ITeamManagementAPI['fetchJoinTeamOrUpdateMembership']>[0],
      })
    },
    [teamId],
  )
  const updateTeamName = useCallback(
    ({ name, errorMessage, infoMessage }: Parameters<ContextTypes['updateTeamName']>[0]) => {
      dispatch({
        type: 'UPDATE_TEAM',
        errorMessage,
        infoMessage,
        payload: {
          method: 'PUT',
          body: { execute: 'updateTeamName', name, teamId },
        } as Parameters<ITeamManagementAPI['fetchUpdateTeamName']>[0],
      })
    },
    [teamId],
  )

  const deleteTeam = useCallback(
    ({
      callback,
      failback,
      errorMessage,
      infoMessage,
    }: Parameters<ContextTypes['deleteTeam']>[0]) => {
      if (!teamId) return
      dispatch({
        type: 'DELETE_TEAM',
        errorMessage,
        infoMessage,
        callback,
        failback,
        payload: {
          method: 'DELETE',
          body: { execute: 'deleteTeam', teamId },
        } as Parameters<ITeamManagementAPI['fetchDeleteTeam']>[0],
      })
    },
    [teamId],
  )

  const disconnectMembership = useCallback(
    ({
      emailFromMembershipToDisconnect,
      errorMessage,
      infoMessage,
      callback,
      failback,
    }: Parameters<ContextTypes['disconnectMembership']>[0]) => {
      if (!teamId) return
      dispatch({
        type: 'UPDATE_TEAM',
        errorMessage,
        infoMessage,
        callback,
        failback,
        payload: {
          method: 'PUT',
          body: { execute: 'disconnectMembership', teamId, emailFromMembershipToDisconnect },
        } as Parameters<ITeamManagementAPI['fetchDisconnectMembership']>[0],
      })
    },
    [teamId],
  )

  const connectOrCreateMembership = useCallback(
    ({
      emailFromMembershipToAdd,
      admin,
      errorMessage,
      infoMessage,
      teamName,
    }: Parameters<ContextTypes['connectOrCreateMembership']>[0]) => {
      if (!teamId) return
      dispatch({
        type: 'UPDATE_TEAM',
        errorMessage,
        infoMessage,
        payload: {
          method: 'PUT',
          body: {
            execute: 'connectOrCreateMembership',
            emailFromMembershipToAdd,
            teamId,
            teamName,
            admin: admin ?? false,
          },
        } as Parameters<ITeamManagementAPI['fetchConnectOrCreateMembership']>[0],
      })
    },
    [teamId],
  )

  const toggleAdmin = useCallback(
    ({
      emailFromMembershipToToggleAdmin,
      errorMessage,
      infoMessage,
    }: Parameters<ContextTypes['toggleAdmin']>[0]) => {
      if (!teamId) return
      dispatch({
        errorMessage,
        infoMessage,
        type: 'UPDATE_TEAM',
        payload: {
          method: 'PUT',
          body: {
            execute: 'toggleAdmin',
            emailFromMembershipToToggleAdmin,
            teamId,
          },
        } as Parameters<ITeamManagementAPI['fetchToggleAdmin']>[0],
      })
    },
    [teamId],
  )
  const resetTeams = useCallback(
    ({ callback }) => {
      dispatch({
        type: 'RESET_TEAMS',
        callback,
        payload: {
          method: 'POST',
          localStorageTeamId,
          body: {
            execute: 'getMemberships',
          },
        } as Parameters<ITeamManagementAPI['fetchGetMemberships']>[0],
      })
    },
    [localStorageTeamId],
  )

  const setTeam = useCallback(
    ({ id }: Parameters<ContextTypes['setTeam']>[0]) =>
      dispatch({
        type: 'SET_TEAM',
        payload: {
          id,
        },
      }),
    [],
  )

  useEffect(() => {
    if (!error) return
    setTimeout(() => {
      if (!mounted.current) return
      setError(undefined)
    }, 3000)
  }, [error])

  useEffect(() => {
    if (!info) return
    setTimeout(() => {
      if (!mounted.current) return
      setInfo(undefined)
    }, 3000)
  }, [info])

  useEffect(() => {
    if (!data.selected) {
      resetTeams({})
    }
  }, [])

  useEffect(() => {
    setLocalStorageTeamId(data.selected?.id)
  }, [data.selected?.id])

  useEffect(() => {
    if (
      (data.selected?.memberships &&
        data.selected?.billing &&
        data.selected?.billing.length &&
        data?.selected?.memberships.length > data.selected?.billing[0].membershipsAllowed) ||
      (data.selected?.billing &&
        data.selected?.billing.length &&
        !data.selected?.billing[0]?.subscriptionActive)
    ) {
      setIsAllowed(false)
    } else {
      setIsAllowed(true)
    }
  }, [data.selected?.billing])

  useEffect(() => {
    if (!data.selected?.id && router.pathname !== '/app/team' && router.pathname !== '/app') return
    getUniqueTeam({ id: data.selected?.id })
  }, [router.pathname])

  const memoizedData = useMemo(() => data, [data])
  const memoizedError = useMemo(() => error, [error])
  const memoizedLoading = useMemo(() => loading, [loading])
  const memoizedIsAllowed = useMemo(() => isAllowed, [isAllowed])
  const memoizedIsReset = useMemo(() => isReset, [isReset])

  return (
    <TeamContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        data: memoizedData,
        error: memoizedError,
        loading: memoizedLoading,
        isAllowed: memoizedIsAllowed,
        isReset: memoizedIsReset,
        setTeam,
        updateTeamName,
        resetTeams,
        getUniqueTeam,
        createTeam,
        joinTeamOrUpdateMembership,
        deleteTeam,
        toggleAdmin,
        disconnectMembership,
        connectOrCreateMembership,
      }}
    >
      {children}
      {error && <Notification type="error" message={error.message} />}
      {info && <Notification type="info" message={info} />}
    </TeamContext.Provider>
  )
}

export default useMyTeam

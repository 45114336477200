/* eslint-disable default-param-last */
import React, {
  type Context,
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useReducer,
} from 'react'
import { useMountedRef, Notification } from '@uniqs-labs/ui-react-lib'
import {
  CreateOrUpdateElements,
  CreateTemplate,
  DeleteTemplate,
  GetElements,
  GetTeamTemplates,
  GetUserTemplates,
  ToggleTemplatePublishState,
  UpdateTemplate,
  DuplicateTemplate,
} from '../pages/api/template'
import { type Template, type Element } from '../adapters/templateManagement'
import useMyTeam from './useMyTeam'
import { fetchPostJSON } from '../utils/api-helpers'

const uri = '/api/template'

export type TemplateStateTypes = {
  selected: { template: Template; elements: Element[] | [] } | undefined
  templates: readonly Template[] | []
}

type SetTemplateReducerTypes = {
  type: 'SET_TEMPLATE'
  payload: {
    templateId: string
    elements: Element[] | []
  }
}

const setTemplateReducer = (
  state: TemplateStateTypes,
  payload: SetTemplateReducerTypes['payload'],
) => {
  const searchedTemplate = state.templates.find((temp) => temp.id === payload.templateId)
  if (!searchedTemplate) return state
  return {
    ...state,
    selected: {
      template: searchedTemplate,
      elements: payload.elements,
    },
  }
}
type CreateTemplateReducerTypes = {
  type: 'CREATE_TEMPLATE'
  payload: {
    template: Template
  }
}

const createTemplateReducer = (
  state: TemplateStateTypes,
  payload: CreateTemplateReducerTypes['payload'],
) => {
  const { template } = payload
  return {
    selected: {
      template,
      elements: [],
    },
    templates: [...state.templates, template],
  }
}

type DuplicateTemplateReducerTypes = {
  type: 'DUPLICATE_TEMPLATE'
  payload: {
    template: Template
  }
}

const duplicateTemplateReducer = (
  state: TemplateStateTypes,
  payload: DuplicateTemplateReducerTypes['payload'],
) => {
  const { template } = payload
  return {
    selected: {
      template,
      elements: [],
    },
    templates: [...state.templates, template],
  }
}

type ToggleTemplatePublishStateReducerTypes = {
  type: 'TOGGLE_TEMPLATE_PUBLISH_STATE'
  payload: {
    template: Template
  }
}

const toggleTemplatePublishStateReducer = (
  state: TemplateStateTypes,
  payload: ToggleTemplatePublishStateReducerTypes['payload'],
) => {
  const { template } = payload

  return {
    ...state,
    selected:
      state?.selected?.template.id === template.id
        ? { ...state.selected, template }
        : state.selected,
    templates: [...state.templates.filter((temp) => temp.id !== template.id), template],
  }
}

type UpdateTemplateReducerTypes = {
  type: 'UPDATE_TEMPLATE'
  payload: {
    template: Template
  }
}

const updateTemplateReducer = (
  state: TemplateStateTypes,
  payload: UpdateTemplateReducerTypes['payload'],
) => {
  const { template } = payload

  return {
    ...state,
    selected:
      state?.selected?.template.id === template.id
        ? { ...state.selected, template }
        : state.selected,
    templates: [...state.templates.filter((temp) => temp.id !== template.id), template],
  }
}

type DeleteTemplateReducerTypes = {
  type: 'DELETE_TEMPLATE'
  payload: {
    template: Template
  }
}

const deleteTemplateReducer = (
  state: TemplateStateTypes,
  payload: DeleteTemplateReducerTypes['payload'],
) => {
  const { template } = payload

  return {
    selected: state?.selected?.template.id === template.id ? undefined : state.selected,
    templates: [...state.templates.filter((temp) => temp.id !== template.id)],
  }
}

type CreateOrUpdateElementsReducerTypes = {
  type: 'CREATE_OR_UPDATE_ELEMENTS'
  payload: {
    templateId: string
    elements: Element[]
  }
}

const createOrUpdateElementsReducer = (
  state: TemplateStateTypes,
  payload: CreateOrUpdateElementsReducerTypes['payload'],
) => {
  const { templateId, elements } = payload
  return {
    ...state,
    selected:
      state?.selected?.template.id === templateId
        ? { ...state.selected, elements }
        : state.selected,
  }
}

type ResetTemplatesReducerTypes = {
  type: 'RESET_TEMPLATES'
  payload: {
    templates: Template[]
  }
}

const resetTemplatesReducer = (
  state: TemplateStateTypes,
  payload: ResetTemplatesReducerTypes['payload'],
) => ({
  selected: undefined,
  templates: payload.templates,
})

type ActionTypes =
  | SetTemplateReducerTypes
  | CreateTemplateReducerTypes
  | DuplicateTemplateReducerTypes
  | ToggleTemplatePublishStateReducerTypes
  | UpdateTemplateReducerTypes
  | DeleteTemplateReducerTypes
  | CreateOrUpdateElementsReducerTypes
  | ResetTemplatesReducerTypes

type ReducerTypes = (state: TemplateStateTypes, newData: ActionTypes) => TemplateStateTypes

const reducer: ReducerTypes = (state, newData) => {
  if (newData.type === 'SET_TEMPLATE') return setTemplateReducer(state, newData.payload)
  if (newData.type === 'CREATE_TEMPLATE') return createTemplateReducer(state, newData.payload)
  if (newData.type === 'DUPLICATE_TEMPLATE') return duplicateTemplateReducer(state, newData.payload)
  if (newData.type === 'TOGGLE_TEMPLATE_PUBLISH_STATE')
    return toggleTemplatePublishStateReducer(state, newData.payload)
  if (newData.type === 'UPDATE_TEMPLATE') return updateTemplateReducer(state, newData.payload)
  if (newData.type === 'DELETE_TEMPLATE') return deleteTemplateReducer(state, newData.payload)
  if (newData.type === 'CREATE_OR_UPDATE_ELEMENTS')
    return createOrUpdateElementsReducer(state, newData.payload)
  if (newData.type === 'RESET_TEMPLATES') return resetTemplatesReducer(state, newData.payload)
  return state
}

// resetReducer gets user and team templates and saves them to templates
// frontend themn sorts them in the right box using the teamID || authorEmail && pubslihed: false

type ContextTypes = {
  data: TemplateStateTypes
  selectedTemplate: TemplateStateTypes['selected']
  error: any
  loading: boolean
  isAllowed: boolean
  setTemplate: (data: {
    inputs: { templateId: string }
    callback?: ((requestedData: any) => void) | undefined
    failback?: () => void | undefined
    infoMessage?: string
    errorMessage?: string
  }) => void
  createTemplate: (data: {
    inputs: {
      title: string
      width?: string
      contentBackgroundColor?: string
      outerBackgroundColor?: string
    }
    callback?: ((requestedData: any) => void) | undefined
    failback?: () => void | undefined
    infoMessage?: string
    errorMessage?: string
  }) => void
  duplicateTemplate: (data: {
    inputs: {
      authorEmail: string
      templateId: string
    }
    callback?: ((requestedData: any) => void) | undefined
    failback?: () => void | undefined
    infoMessage?: string
    errorMessage?: string
  }) => void
  updateTemplate: (data: {
    inputs: {
      templateId: string
      title: string
      width?: string
      outerBackgroundColor?: string
      innerBackgroundColor?: string
      rows?: {}
      sections?: {}
    }
    callback?: ((requestedData: any) => void) | undefined
    failback?: () => void | undefined
    infoMessage?: string
    errorMessage?: string
  }) => void
  createOrUpdateElements: (data: {
    inputs: {
      templateId: string
      elements: {
        id: string
        zoneId: string
        rowId: string
        element: {}
      }[]
    }
    callback?: ((requestedData: any) => void) | undefined
    failback?: () => void | undefined
    infoMessage?: string
    errorMessage?: string
  }) => void
  toggleTemplatePublishState: (data: {
    callback?: ((requestedData: any) => void) | undefined
    failback?: () => void | undefined
    infoMessage?: string
    errorMessage?: string
  }) => void
  deleteTemplate: (data: {
    inputs: { templateId: string }
    callback?: ((requestedData: any) => void) | undefined
    failback?: () => void | undefined
    infoMessage?: string
    errorMessage?: string
  }) => void
  resetTemplates: (data: {
    callback?: ((requestedData: any) => void) | undefined
    failback?: () => void | undefined
    infoMessage?: string
    errorMessage?: string
  }) => void
}

const TemplateContext = createContext({}) as unknown as Context<ContextTypes>

const useMyTemplate = () => useContext(TemplateContext)

export function TemplateProvider({ children }: { children: JSX.Element }) {
  const [data, reduceData] = useReducer(reducer, { selected: undefined, templates: [] })
  const [error, setError] = useState() as any
  const [info, setInfo] = useState() as any
  const [loading, setLoading] = useState(false)
  const [isAllowed, setIsAllowed] = useState(true)
  const mounted = useMountedRef()
  const { data: teams } = useMyTeam()
  const teamId = teams.selected?.id
  const selectedTemplateId = data.selected?.template.id

  const setTemplate: ContextTypes['setTemplate'] = async (input) => {
    const { inputs, failback, callback, errorMessage } = input
    setLoading(true)
    try {
      const elements = (await fetchPostJSON(uri, {
        execute: 'getElements',
        teamId,
        ...inputs,
      } as GetElements['body'])) as Awaited<GetElements['returnTypes']>

      if (!elements) return undefined
      reduceData({
        type: 'SET_TEMPLATE',
        payload: {
          templateId: inputs.templateId,
          elements,
        },
      })
      // setInfo(infoMessage || 'Saved')
      setLoading(false)
      return callback && callback(elements)
    } catch (e) {
      setLoading(false)
      setError(errorMessage || 'Something went wrong')
      return failback && failback()
    }
  }

  const createTemplate: ContextTypes['createTemplate'] = async (input) => {
    const { inputs, failback, callback, errorMessage } = input
    setLoading(true)
    try {
      const template = (await fetchPostJSON(uri, {
        execute: 'createTemplate',
        teamId,
        ...inputs,
      } as CreateTemplate['body'])) as Awaited<CreateTemplate['returnTypes']>
      if (!template) return undefined
      reduceData({
        type: 'CREATE_TEMPLATE',
        payload: {
          template,
        },
      })
      // setInfo(infoMessage)
      setLoading(false)
      return callback && callback(template)
    } catch (e) {
      setLoading(false)
      setError(errorMessage || 'Something went wrong')
      return failback && failback()
    }
  }

  const duplicateTemplate: ContextTypes['duplicateTemplate'] = async (input) => {
    const { inputs, failback, callback, errorMessage } = input
    setLoading(true)
    try {
      const template = (await fetchPostJSON(uri, {
        execute: 'duplicateTemplate',
        teamId,
        ...inputs,
      } as DuplicateTemplate['body'])) as Awaited<DuplicateTemplate['returnTypes']>
      if (!template) return undefined
      reduceData({
        type: 'DUPLICATE_TEMPLATE',
        payload: {
          template,
        },
      })
      // setInfo(infoMessage)
      setLoading(false)
      return callback && callback(template)
    } catch (e) {
      setLoading(false)
      setError(errorMessage || 'Something went wrong')
      return failback && failback()
    }
  }

  const updateTemplate: ContextTypes['updateTemplate'] = async (input) => {
    const { inputs, failback, callback, errorMessage } = input
    try {
      const template = (await fetchPostJSON(uri, {
        execute: 'updateTemplate',
        teamId,
        ...inputs,
      } as UpdateTemplate['body'])) as Awaited<UpdateTemplate['returnTypes']>
      if (!template) return undefined
      reduceData({
        type: 'UPDATE_TEMPLATE',
        payload: {
          template,
        },
      })
      // setInfo(infoMessage || 'Saved')
      return callback && callback(template)
    } catch (e) {
      setError(errorMessage || 'Something went wrong')
      return failback && failback()
    }
  }

  const createOrUpdateElements: ContextTypes['createOrUpdateElements'] = async (input) => {
    const { inputs, failback, callback, errorMessage, infoMessage } = input

    try {
      const elements = (await fetchPostJSON(uri, {
        execute: 'createOrUpdateElements',
        teamId,
        ...inputs,
      } as CreateOrUpdateElements['body'])) as Awaited<CreateOrUpdateElements['returnTypes']>
      if (!elements) return undefined
      reduceData({
        type: 'CREATE_OR_UPDATE_ELEMENTS',
        payload: {
          templateId: inputs.templateId,
          elements,
        },
      })
      setInfo(infoMessage || 'Saved')
      return callback && callback(elements)
    } catch (e) {
      setError(errorMessage || 'Something went wrong')
      return failback && failback()
    }
  }

  const toggleTemplatePublishState: ContextTypes['toggleTemplatePublishState'] = async (input) => {
    const { failback, callback, errorMessage } = input
    try {
      const template = (await fetchPostJSON(uri, {
        execute: 'toggleTemplatePublishState',
        templateId: selectedTemplateId,
      } as ToggleTemplatePublishState['body'])) as Awaited<
        ToggleTemplatePublishState['returnTypes']
      >
      if (!template) return undefined
      reduceData({
        type: 'TOGGLE_TEMPLATE_PUBLISH_STATE',
        payload: {
          template,
        },
      })
      // setInfo(infoMessage || 'Saved')
      return callback && callback(template)
    } catch (e) {
      setError(errorMessage || 'Something went wrong')
      return failback && failback()
    }
  }

  const deleteTemplate: ContextTypes['deleteTemplate'] = async (input) => {
    const { failback, callback, errorMessage, infoMessage } = input
    try {
      const deletedTemplate = (await fetchPostJSON(uri, {
        execute: 'deleteTemplate',
        ...input.inputs,
      } as DeleteTemplate['body'])) as Awaited<DeleteTemplate['returnTypes']>

      if (!deletedTemplate) return undefined
      reduceData({
        type: 'DELETE_TEMPLATE',
        payload: {
          template: deletedTemplate,
        },
      })
      setInfo(infoMessage || 'Deleted successfull')
      return callback && callback(deletedTemplate)
    } catch (e) {
      setError(errorMessage || 'Something went wrong')
      return failback && failback()
    }
  }

  const resetTemplates: ContextTypes['resetTemplates'] = async (input) => {
    const { failback, callback, errorMessage } = input
    try {
      const userTemplates = (await fetchPostJSON(uri, {
        execute: 'getUserTemplates',
      } as GetUserTemplates['body'])) as Awaited<GetUserTemplates['returnTypes']>
      const teamTemplates = (await fetchPostJSON(uri, {
        execute: 'getTeamTemplates',
      } as GetTeamTemplates['body'])) as Awaited<GetTeamTemplates['returnTypes']>
      if (!teamTemplates && !userTemplates) return undefined
      reduceData({
        type: 'RESET_TEMPLATES',
        payload: {
          templates: teamTemplates ? [...userTemplates, ...teamTemplates] : userTemplates,
        },
      })
      // setInfo(infoMessage || 'Saved')
      return (
        callback && callback(teamTemplates ? [...userTemplates, ...teamTemplates] : userTemplates)
      )
    } catch (e) {
      setError(errorMessage || 'Something went wrong')
      return failback && failback()
    }
  }

  useEffect(() => {
    if (!error) return
    setTimeout(() => {
      if (!mounted.current) return
      setError(undefined)
    }, 3000)
  }, [error])

  useEffect(() => {
    if (!info) return
    setTimeout(() => {
      if (!mounted.current) return
      setInfo(undefined)
    }, 3000)
  }, [info])

  useEffect(() => {
    if (!data.templates.length) {
      resetTemplates({})
    }
  }, [teamId])

  useEffect(() => {
    if (!teams.selected?.billing.length) return
    if (
      data.templates &&
      data.templates &&
      data.templates.length &&
      data.templates.length >= teams.selected?.billing[0].templatesAllowed
    ) {
      setIsAllowed(false)
    } else {
      setIsAllowed(true)
    }
  }, [data.templates.length])

  const memoizedData = useMemo(() => data, [data])
  const memoizedError = useMemo(() => error, [error])
  const memoizedSelectedTemplate = useMemo(() => data.selected, [data.selected])
  const memoizedLoading = useMemo(() => loading, [loading])
  const memoizedIsAllowed = useMemo(() => isAllowed, [isAllowed])

  return (
    <TemplateContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        data: memoizedData,
        selectedTemplate: memoizedSelectedTemplate,
        loading: memoizedLoading,
        error: memoizedError,
        isAllowed: memoizedIsAllowed,
        deleteTemplate,
        setTemplate,
        createTemplate,
        duplicateTemplate,
        updateTemplate,
        toggleTemplatePublishState,
        resetTemplates,
        createOrUpdateElements,
      }}
    >
      {children}
      {error && <Notification type="error" message={error.message} />}
      {info && <Notification type="info" message={info} />}
    </TemplateContext.Provider>
  )
}

export default useMyTemplate

/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-constructed-context-values */
import { useSession } from 'next-auth/react'
import React, { useEffect, useState } from 'react'
import { IconUsers, IconUser, IconHome } from '@tabler/icons'
import Image from 'next/image'
import styles from '../styles/components/AppLayout.module.css'
import NavBarApp from './NavBarApp'
import useMyTeam from '../hooks/useMyTeam'
import TeamRequired from './TeamRequired'

export default function AppLayout({
  children,
  teamRequired,
  navbarVisible,
}: {
  children: JSX.Element
  teamRequired: boolean
  navbarVisible?: boolean
}) {
  const { data: session } = useSession()
  const { data: teams } = useMyTeam()
  const selectedTeam = teams.selected
  const [selectedMembership, setSelectedMembership] = useState(
    selectedTeam?.memberships?.find((membership) => membership.email === session?.user.email),
  )

  useEffect(() => {
    setSelectedMembership(
      selectedTeam?.memberships?.find((membership) => membership.email === session?.user.email),
    )
  }, [selectedTeam])

  return (
    <div
      className={[
        styles['app-layout-grid'],
        'custom-scroll',
        navbarVisible ? '' : styles['scroll-to-bottom'],
      ].join(' ')}
    >
      <main>{teamRequired ? <TeamRequired>{children}</TeamRequired> : children}</main>
      {navbarVisible && (
        <footer>
          (
          <NavBarApp
            sections={[
              {
                disabled: false,
                link: `/app/team`,
                icon: selectedTeam?.image ? (
                  <Image
                    src={selectedTeam.image}
                    width="100"
                    height="100"
                    className={styles['user-avatar']}
                  />
                ) : (
                  <IconUsers size={40} />
                ),
              },
              { disabled: false, link: '/app', icon: <IconHome size={40} /> },
              {
                disabled: false,
                link: '/app/account',
                icon: selectedMembership?.image ? (
                  <Image
                    src={selectedMembership.image}
                    width="100"
                    height="100"
                    className={styles['user-avatar']}
                  />
                ) : (
                  (session?.user.image && (
                    <Image
                      src={session?.user.image}
                      width="100"
                      height="100"
                      className={styles['user-avatar']}
                    />
                  )) || <IconUser size={40} />
                ),
              },
            ]}
          />
        </footer>
      )}
    </div>
  )
}

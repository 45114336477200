import React from 'react'
import Image from 'next/image'
import styles from '../styles/components/PulsingLogo.module.css'
import logo from '../assets/images/icon-512x512.png'

function PulsingLogo() {
  return (
    <div className={styles['logo-wrapper']}>
      <Image src={logo} height="80" width="80" className={styles.logo} />
    </div>
  )
}

export default PulsingLogo

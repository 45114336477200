/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react'
import Link from 'next/link'
import styles from '../styles/components/NavBarApp.module.css'

interface INavBarAppP {
  sections: {
    link: string
    icon: JSX.Element
    disabled: boolean
  }[]
}

export default function NavBarApp({ sections }: INavBarAppP) {
  const pathname = typeof window !== 'undefined' ? window.location.pathname : ''
  const navbarSections = sections.map((section) => (
    <div
      key={section.link}
      className={[styles['icon-wrapper'], pathname === section.link ? styles.active : ''].join(' ')}
    >
      {!section.disabled && (
        <Link passHref href={section.link}>
          <span>{section.icon}</span>
        </Link>
      )}
      {section.disabled && (
        <span>
          <span>{section.icon}</span>
        </span>
      )}
    </div>
  ))

  return <div className={styles['navbarapp-grid']}>{navbarSections}</div>
}

import { RippleButton, useMountedRef } from '@uniqs-labs/ui-react-lib'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import useMyTeam from '../hooks/useMyTeam'
import styles from '../styles/components/TeamRequired.module.css'
import PulsingLogo from './PulsingLogo'

export default function TeamRequired({ children }: { children: JSX.Element }) {
  const router = useRouter()
  const { data: teams, loading } = useMyTeam()
  const selectedTeam = teams.selected
  const [initialLoading, setInitialLoading] = useState(!selectedTeam)
  const mounted = useMountedRef()

  useEffect(() => {
    if (selectedTeam || loading) return
    setTimeout(() => {
      if (!mounted.current) return
      setInitialLoading(false)
    }, 3000)
  }, [loading])

  if (initialLoading) return <PulsingLogo />

  if (!initialLoading && !selectedTeam && !loading) {
    return (
      <>
        <div className={styles['no-team-section-background']} />
        <div className={styles['no-team-section']}>
          <div className={styles['headline-wrapper']}>
            <h3>No team yet?</h3>
          </div>
          <RippleButton
            primary
            size="small"
            loading={loading}
            modalOptimized
            label="Create team"
            onClick={() => router.push('/app/team/create')}
          />
        </div>
      </>
    )
  }

  return children
}

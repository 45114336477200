import splitbee from '@splitbee/web'
import { useEffect } from 'react'

function Analytics(): any {
  useEffect(() => {
    if (!window.location.href.includes('mailbuilder.app')) return
    splitbee.init({
      disableCookie: true,
      scriptUrl: '/bee.js',
      apiUrl: '/_hive',
    })
  }, [])
  return null
}

export default Analytics
